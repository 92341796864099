import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
} from "semantic-ui-react";

import * as civilitiesService from "../../../services/civilitiesService";
import * as piecesOfficiellesService from "../../../services/piecesOfficiellesService";

function AssurePane({ formik }) {
  const [civilities, setCivilities] = useState([]);
  const [piecesOfficielles, setPiecesOfficielles] = useState([]);
  const {
    values,
    errors,
    handleBlur,
    handleChange,
  } = formik;

  const fetchCivilities = async () => {
    try {
      const response = await civilitiesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setCivilities(response.data);
      }
    } catch (error) {}
  };
  const fetchPiecesOfficielles = async () => {
    try {
      const response = await piecesOfficiellesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setPiecesOfficielles(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCivilities();
    fetchPiecesOfficielles();

    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Form.Group>
        <Form.Field width={6} error={"nom_assure" in errors} required>
          <label>Noms & Prénoms/Dénomination</label>
          <Input
            value={values.nom_assure}
            onChange={handleChange("nom_assure")}
            onBlur={handleBlur("nom_assure")}
          />
          <small className="field-error">{errors.nom_assure}</small>
        </Form.Field>
        <Form.Field error={"phone_assure" in errors} required width={6}>
          <label>Téléphone</label>
          <Input
            value={values.phone_assure}
            onChange={handleChange("phone_assure")}
            onBlur={handleBlur("phone_assure")}
          />
          <small className="field-error">{errors.phone_assure}</small>
        </Form.Field>
        <Form.Field error={"email_assure" in errors} width={6}>
          <label>Email</label>
          <Input
            value={values.email_assure}
            onChange={handleChange("email_assure")}
            onBlur={handleBlur("email_assure")}
          />
          <small className="field-error">{errors.email_assure}</small>
        </Form.Field>
      </Form.Group>
      <Form.Field  error={"address_assure" in errors}>
        <label>Adresse</label>
        <Input
          value={values.address_assure}
          onChange={handleChange("address_assure")}
          onBlur={handleBlur("address_assure")}
        />
        <small className="field-error">{errors.address_assure}</small>
      </Form.Field>
    </React.Fragment>
  );
}

export default AssurePane;
