import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  GridRow,
  Header,
  Input,
  Segment,
  Tab,
} from "semantic-ui-react";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import IndentiteVehicule from "./components/IndentiteVehicule";
import CalculCommission from "./components/CalculCommission";
import ReferencesConditions from "./components/ReferencesConditions";
import * as categoriesContratsService from "../../services/categoriesContratsService";
import SelectSouscriptorModal from "./components/SelectSouscriptorModal";
import { conversionCV, getPrimeNette } from "../../utils/commons";
import dayjs from "dayjs";
import {
  LIENS_SOUSCRIPEUR_ASSURE,
  POURCENTAGE_ASSURANCE_DUREE,
} from "../../constants";
import AssurePane from "./components/AssurePane";
import * as souscriptionsService from "../../services/souscriptionsService";
import { useNavigate } from "react-router-dom";

const SOURCES_ENERGIE_INDEX = {
  DIESEL: 1,
  ESSENCE: 2,
};

const validationSchema = yup.object().shape({
  idsouscriptions: yup.number().notRequired(),
  idsouscripteurs: yup.number().required(),
  idcategories_contrats: yup.number().required(),
  idzones_circulation: yup.number().required(),
  idsources_energie: yup.number().required(),
  date_souscription: yup.date().required(),
  date_proposition: yup.date().required(),
  date_echeance: yup.date().required(),
  lien_assure: yup.string().required(),
  date_effet: yup.date().required(),
  type_renouvellement: yup
    .string()
    .oneOf(["12_MOIS", "6_MOIS", "3_MOIS"])
    .required(),
  usage_vehicule: yup.string().oneOf(["TRAPUBVOY"]).required(),
  puissance_fiscale: yup.number().min(1).required(),
  nombre_places: yup.number().min(3).max(100).required(),
  marque_vehicule: yup.string().required(),
  carrosserie_vehicule: yup.string().required(),
  date_premiere_mise_en_circulation: yup.date(),
  valeur_a_neuf: yup.number(),
  valeur_venale: yup.number(),
  immatriculation_vehicule: yup.string().required(),
  immatriculation_vehicule_precedente: yup.string(),
  numero_chassis: yup.string().required(),
  numero_permis_conduire: yup.string().required(),
  numero_police: yup.string(),
  date_delivrance_permis: yup.date().required(),
  numero_attestation: yup.string(),
  avec_franchise: yup.boolean(),
  montant_commission: yup.number(),
  prime_nette: yup.number(),
  prime_nette_totale: yup.number(),
  prime_nette_individuelle_conducteur: yup.number(),
  cout_police: yup.number(),
  taxes: yup.number(),
  frais_gestion_pool: yup.number(),
  carte_rose: yup.number(),
  droit_timbre_automobile: yup.number(),
  total_a_payer: yup.number(),
  nom_assure: yup.string().required().min(4).max(255),
  phone_assure: yup.string().required().min(7).max(12),
  email_assure: yup.string().email().max(255),
  address_assure: yup.string().max(255),
  type_avenant: yup.string().max(20),
});

function SouscriptionMonoAdd() {
  const [categoriesContrats, setCategoriesContrats] = useState([]);
  const [selectedSouscripteur, setSelectedSouscripteur] = useState();
  const [openSelectSouscripteurModal, setOpenSelectSouscripteurModal] =
    useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      idsouscriptions: "",
      idsouscripteurs: "",
      idzones_circulation: "",
      idsources_energie: "",
      date_souscription: dayjs().format("YYYY-MM-DD"),
      date_proposition: dayjs().format("YYYY-MM-DD HH:mm"),
      date_echeance: dayjs()
        .add(12, "months")
        .add(-1, "day")
        .format("YYYY-MM-DD HH:mm"),
      date_effet: dayjs().format("YYYY-MM-DD HH:mm"),
      type_renouvellement: "12_MOIS",
      usage_vehicule: "TRAPUBVOY",
      puissance_fiscale: "",
      nombre_places: "",
      marque_vehicule: "",
      carrosserie_vehicule: "",
      date_premiere_mise_en_circulation: "",
      valeur_a_neuf: "",
      valeur_venale: "",
      immatriculation_vehicule: "",
      immatriculation_vehicule_precedente: "",
      numero_chassis: "",
      numero_permis_conduire: "",
      date_delivrance_permis: "",
      numero_attestation: "",
      avec_franchise: false,
      numero_police: "",
      idcategories_contrats: 1,
      montant_commission: 0,
      prime_nette_individuelle_conducteur: 26100,
      prime_nette: 0,
      prime_nette_totale: 0,
      cout_police: 10000,
      taxes: 0,
      frais_gestion_pool: 0,
      carte_rose: 1500,
      droit_timbre_automobile: 0,
      total_a_payer: 0,
      nom_assure: "",
      lien_assure: "",
      phone_assure: "",
      email_assure: "",
      address_assure: "",
      type_avenant: "AFFAIRE_NOUVELLE",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      souscriptionsService
        .create(values)
        .then(
          (response) => {
            console.log(response.data);
            toast(`Souscription enregistrée avec succès!.`, {
              type: "success",
              theme: "colored",
            });
            navigate("/company/polices");
            //handleReset();
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const panes = [
    {
      menuItem: "Assuré",
      render: () => (
        <Tab.Pane>
          <AssurePane formik={formik} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Références Conditions Particulières",
      render: () => (
        <Tab.Pane>
          <ReferencesConditions formik={formik} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Identité du véhicule",
      render: () => (
        <Tab.Pane>
          <IndentiteVehicule formik={formik} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Calcul de la commission",
      render: () => (
        <Tab.Pane>
          <CalculCommission formik={formik} />
        </Tab.Pane>
      ),
    },
  ];

  const fetchCategoriesContrats = async () => {
    const response = await categoriesContratsService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data)) {
      setCategoriesContrats(response?.data);
    }
  };

  useEffect(() => {
    fetchCategoriesContrats();

    return () => {};
  }, []);

  useEffect(() => {
    //setSubmitting(false)
    if (
      values.nombre_places?.length === 0 ||
      values.puissance_fiscale?.length === 0 ||
      values.idsources_energie?.length === 0
    ) {
      return;
    }
    const puissaneCvEnDiesel =
      Number(values.idsources_energie) === SOURCES_ENERGIE_INDEX.DIESEL
        ? Number(values.puissance_fiscale)
        : conversionCV(Number(values.puissance_fiscale), "essence");

    if (puissaneCvEnDiesel === 0) {
      toast(`Puissance fiscale non valide!`, {
        type: "error",
        theme: "colored",
      });
      setFieldValue("puissance_fiscale", "", true);
      return;
    }
    const primeNette = ["3_MOIS", "6_MOIS"].includes(values.type_renouvellement)
      ? Math.floor(
          (getPrimeNette(
            Number(values.nombre_places),
            Number(puissaneCvEnDiesel)
          ) *
            POURCENTAGE_ASSURANCE_DUREE[
              Number(values.type_renouvellement[0])
            ]) /
            100
        )
      : getPrimeNette(Number(values.nombre_places), Number(puissaneCvEnDiesel));

    setFieldValue("prime_nette", primeNette, true);
    const primeTotaleNette =
      values.prime_nette_individuelle_conducteur + primeNette;
    setFieldValue("prime_nette_totale", primeTotaleNette, true);
    let frais_gestion_pool = (primeTotaleNette * 5) / 100;
    setFieldValue(
      "frais_gestion_pool",
      isNaN(parseFloat(frais_gestion_pool))
        ? 0
        : Math.floor(frais_gestion_pool),
      true
    );
    let taxes =
      ((primeTotaleNette + frais_gestion_pool + Number(values.cout_police)) *
        8) /
      100;

    setFieldValue(
      "taxes",
      isNaN(parseFloat(taxes)) ? 0 : Math.floor(taxes),
      true
    );

    let totale_a_payer =
      primeTotaleNette +
      frais_gestion_pool +
      Number(values.cout_police) +
      taxes +
      Number(values.carte_rose);
    setFieldValue(
      "total_a_payer",
      isNaN(parseFloat(totale_a_payer)) ? 0 : Math.floor(totale_a_payer),
      true
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.nombre_places,
    values.puissance_fiscale,
    values.cout_police,
    values.idsources_energie,
    values.type_renouvellement,
  ]);

  useEffect(() => {
    setFieldValue("idsouscripteurs", selectedSouscripteur?.idsouscripteurs);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSouscripteur]);
  useEffect(() => {
    if (values.lien_assure !== "LUI_MEME") {
      setFieldValue("nom_assure", "", true);
      setFieldValue("phone_assure", "", true);
      setFieldValue("email_assure", "", true);
      setFieldValue("address_assure", "", true);
    } else {
      setFieldValue(
        "nom_assure",
        selectedSouscripteur?.groupe_souscripteur === "PARTICULIER"
          ? `${selectedSouscripteur?.first_name || ""} ${
              selectedSouscripteur?.last_name || ""
            }`
          : selectedSouscripteur?.denomination,
        true
      );
      setFieldValue("phone_assure", selectedSouscripteur?.phone, true);
      setFieldValue("email_assure", selectedSouscripteur?.email, true);
      setFieldValue("address_assure", selectedSouscripteur?.address, true);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.lien_assure, values.idsouscripteurs]);

  return (
    <React.Fragment>
      <GridRow as={Form}>
        <Header dividing>Production Mono</Header>
        <Segment>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"idsouscripteurs" in errors}>
              <label>
                Souscripteur
                {selectedSouscripteur ? `(${selectedSouscripteur?.num})` : ""}
              </label>
              <Form.Input
                value={`${selectedSouscripteur?.first_name || ""} ${
                  selectedSouscripteur?.last_name || ""
                }`}
                readOnly
                onClick={() => setOpenSelectSouscripteurModal(true)}
              />
              <small className="field-error">{errors.idsouscripteurs}</small>
            </Form.Field>
            <Form.Field required error={"lien_assure" in errors}>
              <label>Lien avec l'assuré </label>
              <Form.Select
                readOnly
                value={values.lien_assure}
                onChange={(e, data) => {
                  if (isNaN(parseInt(values.idsouscripteurs))) {
                    toast(`Veuillez selectionner un souscripteur Svp!`, {
                      type: "error",
                      theme: "colored",
                    });
                    e.defaultPrevented();
                  }
                  setFieldValue("lien_assure", data.value, true);
                }}
                options={LIENS_SOUSCRIPEUR_ASSURE}
              />
              <small className="field-error">{errors.lien_assure}</small>
            </Form.Field>

            <Form.Field required error={"idcategories_contrats" in errors}>
              <label>Catégorie </label>
              <Form.Select
                readOnly
                value={values.idcategories_contrats}
                onChange={(e, data) =>
                  setFieldValue("idcategories_contrats", data.value, true)
                }
                options={categoriesContrats.map((cat) => ({
                  key: `${cat.idcategories_contrats}`,
                  text: cat.libelle,
                  value: cat.idcategories_contrats,
                }))}
              />
              <small className="field-error">
                {errors.idcategories_contrats}
              </small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field error={"numero_police" in errors}>
              <label>Police</label>
              <Input
                readOnly={true}
                placeholder="Autogénéré"
                value={values.numero_police}
                onChange={handleChange("numero_police")}
                onBlur={handleBlur("numero_police")}
              />
              <small className="field-error">{errors.numero_police}</small>
            </Form.Field>
            <Form.Field error={"numero_proposition" in errors}>
              <label>Numéro de proposition</label>
              <Input
                readOnly={true}
                placeholder="Autogénéré"
                value={values.numero_proposition}
                onChange={handleChange("numero_proposition")}
                onBlur={handleBlur("numero_proposition")}
              />
              <small className="field-error">{errors.numero_proposition}</small>
            </Form.Field>
            <Form.Field error={"date_souscription" in errors}>
              <label>Date de souscription</label>
              <Input
                type="date"
                value={values.date_souscription}
                onChange={handleChange("date_souscription")}
                onBlur={handleBlur("date_souscription")}
              />
              <small className="field-error">{errors.date_souscription}</small>
            </Form.Field>
          </Form.Group>
        </Segment>
        <Tab style={{ marginTop: "40px" }} panes={panes} />
        <Segment>
          <Header content="Tableau des garanties" dividing />
          <Grid columns={4}>
            <Grid.Column>
              <Header as="h5" block>
                <Form.Checkbox checked readOnly label="Responsabilité civile" />
              </Header>
              <div style={{ paddingLeft: "15px" }}>
                <Form.Checkbox checked readOnly label="Défenses" />
                <Form.Checkbox checked readOnly label="Recours" />
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" block>
                <Form.Checkbox
                  checked
                  readOnly
                  label="Individuelle conducteur"
                />
              </Header>
              <div style={{ paddingLeft: "15px" }}>
                <Form.Checkbox checked readOnly label="Décès" />
                <Form.Checkbox
                  checked
                  readOnly
                  label="Incapacité Totale et Partielle"
                />
                <Form.Checkbox checked readOnly label="Frais Médicaux" />
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" block>
                <Form.Checkbox label="Dommages" />
              </Header>
              <div style={{ paddingLeft: "15px" }}>
                <div>
                  <Form.Checkbox
                    label="Franchise"
                    onChange={(e, data) =>
                      setFieldValue("avec_franchise", data.checked)
                    }
                  />
                  {values.avec_franchise && (
                    <Form.Field>
                      <label>Montant</label>
                      <Input />
                    </Form.Field>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" block>
                <Form.Checkbox label="Vol" />
              </Header>
              <div style={{ paddingLeft: "15px" }}>
                <Form.Checkbox label="Vol simple" />
                <Form.Checkbox label="Vol à main armée" />
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" block>
                <Form.Checkbox label="Incendie" />
              </Header>
              <div style={{ paddingLeft: "15px" }}>
                <Form.Checkbox label="Incendie" />
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" block>
                <Form.Checkbox label="Bris de glasses" />
              </Header>
              <div style={{ paddingLeft: "15px" }}>
                <Form.Checkbox label="Bris de glasses" />
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment>
          <Header content="Sommaire" dividing />
          <Grid>
            <Grid.Column width={4}></Grid.Column>
            <Grid.Column width={12}>
              <Grid>
                <Grid.Column width={16}>
                  <Form.Group widths={"equal"}>
                    <Form.Field error={"prime_nette" in errors}>
                      <label>Prime Nette RC</label>
                      <Input
                        min={0}
                        readOnly
                        type="number"
                        value={values.prime_nette}
                        onChange={handleChange("prime_nette")}
                        onBlur={handleBlur("prime_nette")}
                      />
                      <small className="field-error">
                        {errors.prime_nette}
                      </small>
                    </Form.Field>
                    <Form.Field
                      error={"prime_nette_individuelle_conducteur" in errors}
                    >
                      <label>Prime Nette IC</label>
                      <Input
                        min={0}
                        readOnly
                        type="number"
                        value={values.prime_nette_individuelle_conducteur}
                        onChange={handleChange(
                          "prime_nette_individuelle_conducteur"
                        )}
                        onBlur={handleBlur(
                          "prime_nette_individuelle_conducteur"
                        )}
                      />
                      <small className="field-error">
                        {errors.prime_nette_individuelle_conducteur}
                      </small>
                    </Form.Field>
                    <Form.Field error={"prime_nette_totale" in errors}>
                      <label>Prime Nette Total</label>
                      <Input
                        min={0}
                        readOnly
                        type="number"
                        value={values.prime_nette_totale}
                        onChange={handleChange("prime_nette_totale")}
                        onBlur={handleBlur("prime_nette_totale")}
                      />
                      <small className="field-error">
                        {errors.prime_nette_totale}
                      </small>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={"equal"}>
                    <Form.Field error={"taxes" in errors}>
                      <label>Taxe</label>
                      <Input
                        type="number"
                        value={values.taxes}
                        onChange={handleChange("taxes")}
                        onBlur={handleBlur("taxes")}
                      />
                      <small className="field-error">{errors.taxes}</small>
                    </Form.Field>
                    <Form.Field error={"frais_gestion_pool" in errors}>
                      <label>Frais de gestion du Pool</label>
                      <Input
                        type="number"
                        value={values.frais_gestion_pool}
                        onChange={handleChange("frais_gestion_pool")}
                        onBlur={handleBlur("frais_gestion_pool")}
                      />
                      <small className="field-error">
                        {errors.frais_gestion_pool}
                      </small>
                    </Form.Field>
                    <Form.Field error={"carte_rose" in errors}>
                      <label>Carte rose</label>
                      <Input
                        type="number"
                        value={values.carte_rose}
                        onChange={handleChange("carte_rose")}
                        onBlur={handleBlur("carte_rose")}
                      />
                      <small className="field-error">{errors.carte_rose}</small>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={"equal"}>
                    <Form.Field></Form.Field>
                    <Form.Field error={"droit_timbre_automobile" in errors}>
                      <label>Droit de timbre automobile</label>
                      <Input
                        type="number"
                        value={values.droit_timbre_automobile}
                        onChange={handleChange("droit_timbre_automobile")}
                        onBlur={handleBlur("droit_timbre_automobile")}
                      />
                      <small className="field-error">
                        {errors.droit_timbre_automobile}
                      </small>
                    </Form.Field>
                    <Form.Field error={"cout_police" in errors}>
                      <label>Coût de Police</label>
                      <Input
                        type="number"
                        readOnly
                        value={values.cout_police}
                        onChange={handleChange("cout_police")}
                        onBlur={handleBlur("cout_police")}
                      />
                      <small className="field-error">
                        {errors.cout_police}
                      </small>
                    </Form.Field>
                  </Form.Group>
                  <Divider />
                  <Form.Group widths={"equal"}>
                    <Form.Field></Form.Field>
                    <Form.Field></Form.Field>
                    <Form.Field error={"total_a_payer" in errors} width={8}>
                      <label>Total A Payer</label>
                      <Input
                        readOnly={true}
                        type="number"
                        value={values.total_a_payer}
                        onChange={handleChange("total_a_payer")}
                        onBlur={handleBlur("total_a_payer")}
                      />
                      <small className="field-error">
                        {errors.total_a_payer}
                      </small>
                    </Form.Field>
                  </Form.Group>
                  <Divider />

                  <div style={{ textAlign: "right" }}>
                    <Button
                      content="Enregistrer"
                      icon="save"
                      onClick={handleSubmit}
                      positive
                      type="submit"
                      loading={isSubmitting}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Segment>
      </GridRow>
      <SelectSouscriptorModal
        open={openSelectSouscripteurModal}
        selectedSouscripteur={selectedSouscripteur}
        setSelectedSouscripteur={setSelectedSouscripteur}
        setOpen={setOpenSelectSouscripteurModal}
      />
    </React.Fragment>
  );
}

export default SouscriptionMonoAdd;
