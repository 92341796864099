import React, { useEffect, useState } from "react";
import {
  Grid,
  GridRow,
  Header,
  Label,
  List,
  Segment,
  Tab,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as compagniesService from "../../services/comagniesService";
import PolicesPane from "./Components/PolicesPane";
import SinistresPane from "./Components/SinistresPane";
import PiecesJointesPane from "./Components/PiecesJointesPane";

function AssuresDetails() {
  const [company, setCompany] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openAgencyModal, setOpenAgencyModal] = useState(false);
  const params = useParams();

  const panes = [
    {
      menuItem: "Polices",
      render: () => (
        <Tab.Pane>
          <PolicesPane />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Sinistres",
      render: () => (
        <Tab.Pane>
          <SinistresPane />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Pièces jointes",
      render: () => (
        <Tab.Pane>
          <PiecesJointesPane />
        </Tab.Pane>
      ),
    },
  ];

  const fetchCompany = async () => {
    try {
      const response = await compagniesService.fetchOne(params?.uuid);
      if (response?.status === 200) {
        setCompany(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    console.log(params?.uuid);
    if (params?.uuid) {
      fetchCompany();
    }
    return () => {};
  }, [params?.uuid]);

  return (
    <Page title="Détails de l'assuré">
      <GridRow className="AssureDetails">
        <Header>Détails de l'assuré</Header>
        <GridRow as={Segment}>
          <Label ribbon>NSIA</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">#ID Souscripteur</List.Header>
                    <List.Description>{company?.denomination || "Non défini"}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Souscripteur assuré ?</List.Header>
                    <List.Description>
                      {company?.phone_1 || "Non"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Téléphone</List.Header>
                    <List.Description>
                      {company?.phone_1 || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>
                      {company?.phone_1 || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Nom du sousscripteur</List.Header>
                    <List.Description>{company?.siege_social || "Non défini"}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">#ID Assuré</List.Header>
                    <List.Description>
                      {company?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Titre</List.Header>
                    <List.Description>
                      {company?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Commercial</List.Header>
                    <List.Description>
                      {company?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Type souscripteur</List.Header>
                    <List.Description>
                      {company?.rccm || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Nom Assuré/</List.Header>
                    <List.Description>
                      {company?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Profession</List.Header>
                    <List.Description>
                      {company?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>

        <Tab style={{ marginTop: "30px" }} panes={panes} />
      </GridRow>

    </Page>
  );
}

export default AssuresDetails;
