import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import IntermediaireDetails from "../pages/intermediaires/IntermediaireDetails";
import IntermediaireAgencyDetails from "../pages/intermediaires_agencies/IntermediaireAgencyDetails";
import PartnersCompagniesList from "../pages/partenaires/PartnersCompagniesList";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import SouscriptionAdd from "../pages/souscriptions/SouscriptionMonoAdd";
import SouscriptionList from "../pages/souscriptions/SouscriptionMonoList";
import UsersList from "../pages/users/UsersList";

function IntermediaireRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="details" element={<IntermediaireDetails />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscripteurs/*">
          <Route index element={<SouscripteursList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route index element={<SouscriptionList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
          <Route path="add" element={<SouscriptionAdd />} />
        </Route>
        <Route path="agences/*">
          <Route path="details/:id" element={<IntermediaireAgencyDetails />} />
          <Route path="add" element={<SouscriptionAdd />} />
        </Route>
        <Route path="partenaires/*">
          <Route index element={<PartnersCompagniesList />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default IntermediaireRoutes;
