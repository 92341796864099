import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

function AgencyMenus() {
  return (
    <Menu inverted fluid vertical={true} style={{ borderRadius: 0 }}>
      <Menu.Item as={Link} to="agency/dashboard">
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="agency/souscripteurs">
        <span>
          <Icon name="users" /> Souscripteurs
        </span>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>
          <Icon name="bus" /> Automobiles
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item as={Link} to="agency/souscriptions/mono">
            Production Mono
          </Menu.Item>
          <Menu.Item as={Link} to="agency/souscriptions/flotte">
            Production Flotte
          </Menu.Item>
          <Menu.Item as="a">Déclaration de sinistre</Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>
          <Icon name="file alternate" /> Etats
        </Menu.Header>
        <Menu.Menu>
          <Menu.Menu>
            <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
          </Menu.Menu>
          <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item as="a">Bordereaux Avis d'échéance</Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item as={Link} to="agency/details">
        <span>
          <Icon name="building" /> Compagnie
        </span>
      </Menu.Item>
      <Menu.Item as="a">
        <span>
          <Icon name="user" /> Mon profile
        </span>
      </Menu.Item>
    </Menu>
  );
}

export default AgencyMenus;
