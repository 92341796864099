import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
function PoolMenus() {
  return (
    <Menu inverted fluid vertical={true} style={{ borderRadius: 0 }}>
      <Menu.Item as={Link} to="pool/dashboard">
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>
          <Icon name="briefcase" /> Administration
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item as={Link} to="pool/administration/compagnies">
            Compagnies
          </Menu.Item>
          <Menu.Item as={Link} to="pool/administration/intermediaires">
            Intermédiaires
          </Menu.Item>
          <Menu.Item as={Link} to="pool/administration/users">
            Utilisateurs
          </Menu.Item>
          <Menu.Item as="a">Autorisations</Menu.Item>
          <Menu.Item as="a">Stocks</Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>
          <Icon name="bus" /> Automobiles
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item as={Link} to="pool/polices">
            Liste des polices
          </Menu.Item>
          <Menu.Item as="a">Déclaration de sinistre</Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>
          <Icon name="file alternate" /> Etats
        </Menu.Header>
        <Menu.Menu>
          <Menu.Menu>
            <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
          </Menu.Menu>
          <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item as="a">Bordereaux Avis d'échéance</Menu.Item>
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>
          <Icon name="book" /> Dictionnaire
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item as={Link} to="pool/dictionnaire/categories-contrats">
            Catégories de contrats
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/sources-energies">
            Sources d'énergie
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/zones-circulation">
            Zones de circulation
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/civilites">
            Civilités
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/professions">
            Professions
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/pays">
            pays
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/car-brands">
            Marques de voiture
          </Menu.Item>
          <Menu.Item as={Link} to="pool/dictionnaire/pieces-officielles">
            Pièces officielles
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
    </Menu>
  );
}

export default PoolMenus;
