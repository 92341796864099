import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  Dropdown,
  Icon,
  Menu,
  Sidebar,
  SidebarPusher,
} from "semantic-ui-react";
import { authState } from "../atoms/authState";
import { USERS_ROLE_ENUMS } from "../constants";
import "../styles/dashboard_layout.min.css";
import { removeUserFromStorage } from "../utils/auth";
import AgencyMenus from "./sidebarMenus/AgencyMenus";
import CompanyMenus from "./sidebarMenus/CompanyMenus";
import IntermediaireMenus from "./sidebarMenus/IntermediaireMenus";
import PoolMenus from "./sidebarMenus/PoolMenus";

const DashboardLayout = () => {
  const [authValue, setAuthValue] = useRecoilState(authState);

  const [visible, setVisible] = useState(false);

  const handleLogout = () => {
    removeUserFromStorage();
    setAuthValue(null);
  };

  const renderMenu = () => {
    if (
      [USERS_ROLE_ENUMS.ROOT_ADMIN, USERS_ROLE_ENUMS.POOL_USER].includes(
        authValue?.user?.user_role
      )
    ) {
      return <PoolMenus />;
    }
    if ([USERS_ROLE_ENUMS.COMPANY_USER].includes(authValue?.user?.user_role)) {
      return <CompanyMenus />;
    }
    if (
      [
        USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
        USERS_ROLE_ENUMS.COURTIER_USER,
      ].includes(authValue?.user?.user_role)
    ) {
      return <IntermediaireMenus />;
    }
    if (
      [
        USERS_ROLE_ENUMS.COMPANY_AGENCY_USER,
        USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER,
      ].includes(authValue?.user?.user_role)
    ) {
      return <AgencyMenus />;
    }
  };

  return (
    <div className="DashboardLayout">
      <Sidebar
        animation="overlay"
        icon="labeled"
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
      >
        {renderMenu()}
      </Sidebar>
      <div className="pusher">
        <div className="full height">
          <div className="toc">{renderMenu()}</div>
          <div className="page-content">
            <Menu fixed="top" secondary>
              <Menu.Item className="brand" as="a">
                POOL TPV CONGO
              </Menu.Item>
              <Menu.Menu className="right desktop only">
                <Dropdown item text={`${authValue?.user?.first_name}`}>
                  <Dropdown.Menu>
                    <Dropdown.Item>Electronics</Dropdown.Item>
                    <Dropdown.Item>Automotive</Dropdown.Item>
                    <Dropdown.Item>Home</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Menu.Item className="log-out" as="a" onClick={handleLogout}>
                  <Icon name="log out" />
                  Déconnexion
                </Menu.Item>
              </Menu.Menu>
              <div className="right menu mobile only">
                <Menu.Item as="a" onClick={() => setVisible(!visible)}>
                  <Icon name="bars" />
                </Menu.Item>
              </div>
            </Menu>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
