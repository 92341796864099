import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`jobs`);
};
export const fetchOne = async (id) => {
  return api.get(`jobs/${id}`);
};
export const create = async (data) => {
  return api.post(`jobs`, data);
};
