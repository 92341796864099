import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
} from "semantic-ui-react";
import * as sourcesEnergiesService from "../../../services/sourcesEnergiesService";
import * as zonesCirculationService from "../../../services/zonesCirculationService";


function IndentiteVehicule({ formik }) {
  const [sourcesEnergies, setSourcesEnergies] = useState([]);
  const [zonesCirculation, setZonesCirculation] = useState([]);

  const { values, errors, handleBlur, handleChange, setFieldValue } = formik;

  const fetchSourcesEnergies = async () => {
    const response = await sourcesEnergiesService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data)) {
      const _sourcesEnergies = response?.data;
      _sourcesEnergies.push({
        idsources_energie: "",
        libelle: "Choisir...",
        description: "",
      });
      setSourcesEnergies(_sourcesEnergies);
    }
  };

  const fetchZonesCirculation = async () => {
    const response = await zonesCirculationService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data)) {
      setZonesCirculation(response?.data);
    }
  };

  useEffect(() => {
    fetchSourcesEnergies();
    fetchZonesCirculation();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Form.Group widths={"4"}>
        <Form.Field required error={"usage_vehicule" in errors}>
          <label>Usage du véhicule</label>
          <Form.Select
            value={values.usage_vehicule}
            readOnly
            options={[
              {
                key: `TRAPUBVOY`,
                text: "Transport Public Voyageur",
                value: "TRAPUBVOY",
              },
            ]}
          />
          <small className="field-error">{errors.usage_vehicule}</small>
        </Form.Field>
        <Form.Field error={"idzones_circulation" in errors}>
          <label>Zone de circulation</label>
          <Form.Select
            search
            value={values.idzones_circulation}
            options={zonesCirculation.map((zone) => ({
              key: `${zone.idzones_circulation}`,
              text: zone.libelle,
              value: zone.idzones_circulation,
            }))}
            onChange={(e, data) =>
              setFieldValue("idzones_circulation", data.value, true)
            }
          />
          <small className="field-error">{errors.idzones_circulation}</small>
        </Form.Field>
        <Form.Field required error={"puissance_fiscale" in errors}>
          <label>Puissance fiscale</label>
          <Form.Input
            min={1}
            value={values.puissance_fiscale}
            onChange={handleChange("puissance_fiscale")}
            onBlur={handleBlur("puissance_fiscale")}
          />
          <small className="field-error">{errors.puissance_fiscale}</small>
        </Form.Field>
        <Form.Field error={"nombre_places" in errors} required>
          <label>Nombre de places</label>
          <Input
            type="number"
            min={3}
            max={100}
            value={values.nombre_places}
            onChange={handleChange("nombre_places")}
            onBlur={handleBlur("nombre_places")}
          />
          <small className="field-error">{errors.nombre_places}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"4"}>
        <Form.Field required error={"idsources_energie" in errors}>
          <label>Source d'énergie</label>
          <Form.Select
            value={values.idsources_energie || ""}
            onChange={(e, data) =>
              setFieldValue("idsources_energie", data.value)
            }
            options={sourcesEnergies.map((source) => ({
              key: `${source.idsources_energie}`,
              text: source.libelle,
              value: source.idsources_energie,
            }))}
          />
          <small className="field-error">{errors.idsources_energie}</small>
        </Form.Field>
        <Form.Field error={"marque_vehicule" in errors}>
          <label>Marque</label>
          <Input
            type="text"
            value={values.marque_vehicule}
            onChange={handleChange("marque_vehicule")}
            onBlur={handleBlur("marque_vehicule")}
          />
          <small className="field-error">{errors.marque_voiture}</small>
        </Form.Field>
        <Form.Field error={"carrosserie_vehicule" in errors}>
          <label>Carossérie</label>
          <Input
            type="text"
            value={values.carrosserie_vehicule}
            onChange={handleChange("carrosserie_vehicule")}
            onBlur={handleBlur("carrosserie_vehicule")}
          />
          <small className="field-error">{errors.carrosserie_vehicule}</small>
        </Form.Field>
        <Form.Field error={"date_premiere_mise_en_circulation" in errors}>
          <label>Date de 1ère mise en circulation</label>
          <Input
            type="date"
            value={values.date_premiere_mise_en_circulation}
            onChange={handleChange("date_premiere_mise_en_circulation")}
            onBlur={handleBlur("date_premiere_mise_en_circulation")}
          />
          <small className="field-error">
            {errors.date_premiere_mise_en_circulation}
          </small>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"4"}>
        <Form.Field error={"valeur_a_neuf" in errors}>
          <label>Valeur à neuf</label>
          <Input
            type="number"
            value={values.valeur_a_neuf}
            onChange={handleChange("valeur_a_neuf")}
            onBlur={handleBlur("valeur_a_neuf")}
          />
          <small className="field-error">{errors.valeur_a_neuf}</small>
        </Form.Field>
        <Form.Field error={"valeur_venale" in errors}>
          <label>Valeur vénale</label>
          <Input
            type="text"
            value={values.valeur_venale}
            onChange={handleChange("valeur_venale")}
            onBlur={handleBlur("valeur_venale")}
          />
          <small className="field-error">{errors.valeur_venale}</small>
        </Form.Field>
        <Form.Field required error={"immatriculation_vehicule" in errors}>
          <label>Immatriculation du véhicule</label>
          <Input
            type="text"
            value={values.immatriculation_vehicule}
            onChange={handleChange("immatriculation_vehicule")}
            onBlur={handleBlur("immatriculation_vehicule")}
          />
          <small className="field-error">
            {errors.immatriculation_vehicule}
          </small>
        </Form.Field>
        <Form.Field error={"immatriculation_vehicule_precedente" in errors}>
          <label>Immatriculation précédente</label>
          <Input
            type="text"
            value={values.immatriculation_vehicule_precedente}
            onChange={handleChange("immatriculation_vehicule_precedente")}
            onBlur={handleBlur("immatriculation_vehicule_precedente")}
          />
          <small className="field-error">
            {errors.immatriculation_vehicule_precedente}
          </small>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"4"}>
        <Form.Field required error={"numero_chassis" in errors}>
          <label>Numéro du châssis</label>
          <Input
            type="text"
            value={values.numero_chassis}
            onChange={handleChange("numero_chassis")}
            onBlur={handleBlur("numero_chassis")}
          />
          <small className="field-error">{errors.numero_chassis}</small>
        </Form.Field>
        <Form.Field error={"numero_permis_conduire" in errors}>
          <label>Numéro de Permis du conducteur</label>
          <Input
            type="text"
            value={values.numero_permis_conduire}
            onChange={handleChange("numero_permis_conduire")}
            onBlur={handleBlur("numero_permis_conduire")}
          />
          <small className="field-error">{errors.numero_permis_conduire}</small>
        </Form.Field>
        <Form.Field error={"date_delivrance_permis" in errors}>
          <label>Date du permis du conducteur</label>
          <Input
            type="date"
            value={values.date_delivrance_permis}
            onChange={handleChange("date_delivrance_permis")}
            onBlur={handleBlur("date_delivrance_permis")}
          />
          <small className="field-error">{errors.date_delivrance_permis}</small>
        </Form.Field>
        <Form.Field error={"numero_attestation" in errors}>
          <label>Numéro d’Attestation</label>
          <Input
            type="text"
            value={values.numero_attestation}
            onChange={handleChange("numero_attestation")}
            onBlur={handleBlur("numero_attestation")}
          />
          <small className="field-error">{errors.numero_attestation}</small>
        </Form.Field>
      </Form.Group>
    </React.Fragment>
  );
}

export default IndentiteVehicule;
