import React from "react";
import {
  Button,
  Form,
  Input,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as assuresServices from "../../services/assuresServices";

const validationSchema = yup.object().shape({
  first_name: yup.string().max(150).required(),
  last_name: yup.string().max(150).required(),
  civility: yup.string().required(),
  email: yup.string().email().required(),
  adress: yup.string().max(255),
  phone: yup.string().min(8).max(16).required(),
  profession: yup.string().max(255),
});
function AssuresFormModal({ callback = () => {}, open = false, setOpen }) {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      civility: "",
      email: "",
      adress: "",
      phone: "",
      profession: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      assuresServices
        .create(values)
        .then(
          (response) => {
            console.log(response.data);
            toast(`Assuré ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter un assuré</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Nom</label>
              <Input
                value={values.last_name}
                onChange={handleChange("last_name")}
                onBlur={handleBlur("last_name")}
              />
              <small className="field-error">{errors.last_name}</small>
            </Form.Field>
            <Form.Field>
              <label>Prénom</label>
              <Input
                value={values.first_name}
                onChange={handleChange("first_name")}
                onBlur={handleBlur("first_name")}
              />
              <small className="field-error">{errors.first_name}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field error={"civility" in errors}>
              <label>Titre</label>
              <Form.Select
                search
                fluid
                onChange={(e, data) => setFieldValue("civility", data.value)}
                options={[
                  {
                    key: "DEFAULTT",
                    text: "Choisir...",
                    value: "",
                  },
                  {
                    key: "Mme",
                    text: "Mme",
                    value: "Mme",
                  },
                  {
                    key: "Mlle",
                    text: "Mlle",
                    value: "Mlle",
                  },
                  {
                    key: "Mr",
                    text: "Mr",
                    value: "Mr",
                  },
                  {
                    key: "AUTRE",
                    text: "AUTRE",
                    value: "AUTRE",
                  },
                ]}
                placeholder="Choisr..."
              />
              <small className="field-error">{errors.civility}</small>
            </Form.Field>
            <Form.Field error={"profession" in errors}>
              <label>Profession</label>
              <Input
                value={values.profession}
                onChange={handleChange("profession")}
                onBlur={handleBlur("profession")}
              />
              <small className="field-error">{errors.profession}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field error={"phone" in errors}>
              <label>Téléphone</label>
              <Input
                value={values.phone}
                onChange={handleChange("phone")}
                onBlur={handleBlur("phone")}
              />
              <small className="field-error">{errors.phone}</small>
            </Form.Field>
            <Form.Field error={"email" in errors}>
              <label>Email</label>
              <Input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
              />
              <small className="field-error">{errors.email}</small>
            </Form.Field>
          </Form.Group>
          <Form.Field error={"adress" in errors}>
            <label>Adresse</label>
            <Form.TextArea
              value={values.adress}
              onChange={handleChange("adress")}
              onBlur={handleBlur("adress")}
            />
            <small className="field-error">{errors.adress}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AssuresFormModal;
