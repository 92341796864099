import { api } from "../axios/api";

export const findAll = async () => {
  return api.get(`polices`);
};

export const findOne = async (id) => {
  return api.get(`polices/${id}`);
};
export const create = async (data) => {
  return api.post(`polices`, data);
};

export const makePayment = async (data) => {
  return api.post(`polices/payments`, data);
};
export const changementImmaticulation = async (data) => {
  return api.put(`polices/actes/changement-immatriculation`, data);
};
export const changementDeNom = async (data) => {
  return api.put(`polices/actes/changement-nom`, data);
};
