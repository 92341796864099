import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`souscripteurs`);
};

export const fetchOne = async (id) => {
  return api.get(`souscripteurs/${id}`);
};
export const create = async (data) => {
  return api.post(`souscripteurs`, data);
};
