import React, { useState } from "react";
import {
  Divider,
  Form,
  Modal,
  Radio,
} from "semantic-ui-react";
import ParticulierForm from "../SouscripteurForms/ParticulierForm";
import EntrepriseForm from "../SouscripteurForms/EntrepriseForm";

function SouscripteursFormModal({
  callback = () => {},
  open = false,
  setOpen,
}) {
  const [groupSouscripteur, setGroupSouscripteur] = useState("PARTICULIER");
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Modal.Header>Ajouter un souscripteur</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Group inline required>
              <label>Type de souscripteur </label>
              <Form.Field
                control={Radio}
                label="Particulier"
                value="PARTICULIER"
                onChange={() => setGroupSouscripteur("PARTICULIER")}
                checked={groupSouscripteur === "PARTICULIER"}
              />
              <Form.Field
                control={Radio}
                label="Entreprise"
                value="ENTREPRISE"
                onChange={() => setGroupSouscripteur("ENTREPRISE")}
                checked={groupSouscripteur === "ENTREPRISE"}
              />
            </Form.Group>
          </Form>
          <Divider />
          {groupSouscripteur === "PARTICULIER" ? (
            <ParticulierForm
              callback={callback}
              setOpen={setOpen}
              open={open}
            />
          ) : (
            <EntrepriseForm callback={callback} setOpen={setOpen} open={open} />
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default SouscripteursFormModal;
