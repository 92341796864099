import React from "react";
import {
  Button,
  Form,
  Header,
  Image,
  Input,
  Modal,
  Select,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as intermediairesAgenciesService from "../../services/intermediairesAgenciesService";

const validationSchema = yup.object().shape({
  first_name: yup.string().max(150).required(),
  last_name: yup.string().max(150).required(),
  email: yup.string().email().trim().required(),
  password: yup.string().trim().min(8).max(60),
  phone: yup.string().min(8).max(16).required(),
  active: yup.boolean(),
});
//validationSchema.concat(yup.object({ password: yup.string().required() }));

function IntermediaireAgencyUserFormModal({
  callback = () => {},
  open = false,
  setOpen = () => {},
  agencyId,
}) {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone: "",
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      intermediairesAgenciesService
        .addUser(values, agencyId)
        .then(
          (response) => {
            toast(`Utilisateur ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter un utilisateur</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"last_name" in errors}>
              <label>Nom</label>
              <Input
                value={values.last_name}
                onChange={handleChange("last_name")}
                onBlur={handleBlur("last_name")}
              />
              <small className="field-error">{errors.last_name}</small>
            </Form.Field>
            <Form.Field required error={"first_name" in errors}>
              <label>Prénom</label>
              <Input
                value={values.first_name}
                onChange={handleChange("first_name")}
                onBlur={handleBlur("first_name")}
              />
              <small className="field-error">{errors.first_name}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"phone" in errors}>
              <label>Téléphone</label>
              <Input
                value={values.phone}
                onChange={handleChange("phone")}
                onBlur={handleBlur("phone")}
              />
              <small className="field-error">{errors.phone}</small>
            </Form.Field>
            <Form.Field required error={"email" in errors}>
              <label>Email</label>
              <Input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
              />
              <small className="field-error">{errors.email}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"password" in errors}>
              <label>Mot de passe</label>
              <Input
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                name="password"
                action={{
                  icon: "magic",
                  onClick: (e) => {
                    e.preventDefault();
                    const randomPassword = Math.random().toString(36).slice(-8);
                    setFieldValue("password", randomPassword, false);
                  },
                }}
              />
              <small className="field-error">{errors.password}</small>
            </Form.Field>
            <Form.Field error={"active" in errors}>
              <Form.Checkbox
                onChange={(e, data) => setFieldValue("active", data.checked)}
                label="Compte actif"
                defaultChecked
                checked={values.active}
              />
              <small className="field-error">{errors.active}</small>
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediaireAgencyUserFormModal;
