import React, { Fragment, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import dayjs from "dayjs";
import { generateAvenantserieNumber } from "../../utils/commons";
import EncaissementModal from "./EncaissementModal";
import ChangementImmatriculationModal from "../modalForms/ActesDeGestion.js/ChangementImmatriculationModal";
import ChangementDeNomModal from "../modalForms/ActesDeGestion.js/ChangementDeNomModal";

function ActesDeGestionPane({
  avenants = [],
  idpolices = null,
  onSuccessPaymentCallBack = () => {},
  onSuccessCallBack = () => {},
}) {
  const [openEncaissementModal, setOpenEncaissementModal] = useState(false);
  const [
    openChangementImmatriculationModal,
    setOpenChangementImmatriculationModal,
  ] = useState(false);
  const [openChangementDeNomModal, setOpenChangementDeNomModal] =
    useState(false);
  const [selectedAvenantId, setSelectedAvenantId] = useState();

  const authValue = useRecoilValue(authState);
  const navigate = useNavigate();

  return (
    <Fragment>
      <GridRow>
        <>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des actes</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Dropdown
                pointing="right"
                style={{ zIndex: 999 }}
                icon={null}
                trigger={
                  <Button
                    style={{ marginLeft: "10px" }}
                    circular
                    icon={<Icon name="add" />}
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item content="Résiliation" />
                  <Dropdown.Item content="Suspension" />
                  <Dropdown.Item content="Modification de prise d'éffet" />
                  <Dropdown.Item
                    content="Changement d'immatriculation"
                    onClick={() => setOpenChangementImmatriculationModal(true)}
                  />
                  <Dropdown.Item content="Remise en vigueur" />
                  <Dropdown.Item content="Incorporation" />
                  <Dropdown.Item content="Retrait" />
                  <Dropdown.Item content="Emission d'attestation" />
                  <Dropdown.Item content="Extension des garanties" />
                  <Dropdown.Item
                    content="Avenant de changement de nom"
                    onClick={() => setOpenChangementDeNomModal(true)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Numéro</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Période</Table.HeaderCell>
                <Table.HeaderCell>Création</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {avenants?.map((avenant, index) => {
                return (
                  <Table.Row key={`${avenant.idavenants}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {generateAvenantserieNumber(avenant.numero_avenant)}
                    </Table.Cell>
                    <Table.Cell>{avenant.types_avenants?.code}</Table.Cell>
                    <Table.Cell>
                      {dayjs(avenant.date_effet).format("DD-MM-YYYY")} -{" "}
                      {dayjs(avenant.date_effet)
                        .add(Number(avenant.renouvellement.slice(-2)), "months")
                        .add(-1, "day")
                        .format("DD-MM-YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      {dayjs(avenant.created_at).format("DD-MM-YYYY")}
                    </Table.Cell>

                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() =>
                              navigate(`details/${avenant?.idavenant}`)
                            }
                          />
                          <Dropdown.Item
                            icon={<Icon name="payment" />}
                            content="Encaissement"
                            onClick={() => {
                              setSelectedAvenantId(avenant.idavenants);
                              setOpenEncaissementModal(true);
                            }}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      </GridRow>
      <EncaissementModal
        open={openEncaissementModal}
        setOpen={setOpenEncaissementModal}
        avenant={avenants.find(
          (avenant) => avenant?.idavenants === selectedAvenantId
        )}
        onSuccessPaymentCallBack={onSuccessPaymentCallBack}
      />
      <ChangementImmatriculationModal
        open={openChangementImmatriculationModal}
        setOpen={setOpenChangementImmatriculationModal}
        idpolices={idpolices}
        avenants={avenants}
        onSuccessCallBack={onSuccessCallBack}
      />
      <ChangementDeNomModal
        open={openChangementDeNomModal}
        setOpen={setOpenChangementDeNomModal}
        idpolices={idpolices}
        avenants={avenants}
        onSuccessCallBack={onSuccessCallBack}
      />
    </Fragment>
  );
}

export default ActesDeGestionPane;
