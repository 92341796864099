import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
} from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as compagniesPartnersService from "../../services/compagniesPartnersService";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { toast } from "react-toastify";

function CompanyPartnersDetails() {
  const [intermediaire, setIntermediaire] = useState();
  const [commissions, setCommissions] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openIntermediaireAgencyModal, setOpenIntermediaireAgencyModal] =
    useState(false);
  const params = useParams();

  const fetchIntermediaire = async () => {
    try {
      const response = await compagniesPartnersService.fetchOne(params?.uuid);

      if (response?.status === 200) {
        setIntermediaire(response?.data);
        setCommissions(response?.data.commissions)
      }
    } catch (error) {}
  };

  const updateCommissions = () => {
    compagniesPartnersService
      .updateCommissions({
        commissions: commissions,
        idintermediaires: intermediaire.idintermediaires,
      })
      .then(
        () => {
          toast(`Commissions modifiée avec succès.`, {
            type: "success",
            theme: "colored",
          });
        },
        (reason) => {
          toast(`${reason?.response?.data || reason?.message}`, {
            type: "error",
            theme: "colored",
          });
        }
      );
  };

  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchIntermediaire();
    return () => {};
  }, [params?.uuid]);

  return (
    <Page title="Intermédiaire détails">
      <GridRow className="IntermediaireDetails">
        <Header>Détails de l'intermédiaire</Header>
        <GridRow as={Segment}>
          <Label ribbon>Intermédiaire</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.denomination}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.phone_1 || "-"}/
                      {intermediaire?.intermediaires?.phone_2 || "-"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Siège Social</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.siege_social}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">RCCM/</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.rccm || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Web/</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>
                Commissions <small>(en %)</small>
              </Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input type="number">
                <input
                  value={commissions}
                  onChange={(e) => setCommissions(e.target.value)}
                />
              </Input>

              <Button
                style={{ marginLeft: "10px" }}
                icon={<Icon name="save" />}
                onClick={() => updateCommissions()}
              />
            </Grid.Column>
          </Grid>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default CompanyPartnersDetails;
