import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`civilities`);
};
export const fetchOne = async (id) => {
  return api.get(`civilities/${id}`);
};
export const create = async (data) => {
  return api.post(`civilities`, data);
};
