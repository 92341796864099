import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`intermediaires`);
};

export const fetchOne = async (id) => {
  return api.get(`intermediaires/${id}`);
};

export const fetchOwner = async () => {
  return api.get(`intermediaires/details/owner`);
};

export const fetchDetails = async (id) => {
  return api.get(`intermediaires/details/${id}`);
};

export const create = async (data) => {
  return api.post(`intermediaires`, data);
};
export const addUser = async (data) => {
  return api.post(`intermediaires/user/add`, data);
};
