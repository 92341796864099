import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as compagniesService from "../../services/comagniesService";
import CompanyUserFormModal from "../../components/modalForms/CompanyUserFormModal";
import { USERS_ROLE_ENUMS } from "../../constants";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import CompanyAgencyFormModal from "../../components/modalForms/CompanyAgencyFormModal";

function CompanyDetails() {
  const [company, setCompany] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openAgencyModal, setOpenAgencyModal] = useState(false);
  const authValue = useRecoilValue(authState);

  const params = useParams();
  const navigate = useNavigate();

  const fetchCompany = async () => {
    try {
      const response = await (authValue?.user?.user_role ===
      USERS_ROLE_ENUMS.COMPANY_USER
        ? compagniesService.fetchOwner()
        : compagniesService.fetchOne(params?.uuid || ""));

      if (response?.status === 200) {
        setCompany(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchCompany();
    return () => {};
  }, [params?.uuid]);

  return (
    <Page title="Compagnie détails">
      <GridRow className="CompagnyDetails">
        <Header>Détails de la compagnie</Header>
        <GridRow as={Segment}>
          <Label ribbon>{company?.denomination}</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>{company?.denomination}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {company?.phone_1 || "-"}/{company?.phone_2 || "-"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Siège Social</List.Header>
                    <List.Description>{company?.siege_social}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>
                      {company?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">RCCM/</List.Header>
                    <List.Description>
                      {company?.rccm || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Web/</List.Header>
                    <List.Description>
                      {company?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des utilisateurs</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenUserModal(true)}
              />
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {company?.users_compagnies?.map((user_company, index) => {
                return (
                  <Table.Row key={`${user_company.idusers_compagnies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{user_company?.users?.last_name}</Table.Cell>
                    <Table.Cell>{user_company?.users?.first_name}</Table.Cell>
                    <Table.Cell>{user_company?.users?.email}</Table.Cell>
                    <Table.Cell>{user_company?.users?.phone}</Table.Cell>
                    <Table.Cell>{`${
                      user_company?.is_admin === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell>{`${
                      user_company?.users?.active === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </GridRow>
        <GridRow as={Segment} style={{ marginTop: "30px" }}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des Agences</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              {![
                USERS_ROLE_ENUMS.POOL_USER,
                USERS_ROLE_ENUMS.ROOT_ADMIN,
              ].includes(authValue?.user?.user_role) && (
                <Button
                  style={{ marginLeft: "10px" }}
                  circular
                  icon={<Icon name="add" />}
                  onClick={() => setOpenAgencyModal(true)}
                />
              )}
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dénomination</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Adresse</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {company?.compagnies_agencies?.map((agency, index) => {
                return (
                  <Table.Row key={`${agency.idcompagnies_agencies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{agency.denomination}</Table.Cell>
                    <Table.Cell>
                      {agency.phone_1}/{agency.phone_2}
                    </Table.Cell>
                    <Table.Cell>{agency.adress}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() =>
                              navigate(
                                `/company/agences/details/${agency.idcompagnies_agencies}`
                              )
                            }
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </GridRow>
      </GridRow>
      <CompanyUserFormModal
        open={openUserModal}
        setOpen={setOpenUserModal}
        company={company}
        callback={fetchCompany}
      />
      <CompanyAgencyFormModal
        open={openAgencyModal}
        setOpen={setOpenAgencyModal}
        callback={fetchCompany}
      />
    </Page>
  );
}

export default CompanyDetails;
