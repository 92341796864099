import { TARIF_POOL_TPV_ANNUEL, USERS_ROLE_ENUMS_OPTIONS } from "../constants";

export const getTypeIntermediaireText = (key = "") => {
  let text = "Type inconnu";

  switch (key) {
    case "AGENT_GENERAL":
      text = "Agent Général";
      break;
    case "COURTIER":
      text = "Courtier";
      break;

    default:
      break;
  }

  return text;
};

export const getUserRoleReadableText = (key = "") => {
  let role = USERS_ROLE_ENUMS_OPTIONS.find((role) => role.key === key);

  return role?.text || "Type inconnu";
};

export const getPrimeNette = (nombre_places, puissance_fiscale) => {
  const FORCE_FISCALE_MAX = 21; //forcr fiscale max à considérer
  let tarif = 0;
  const line = TARIF_POOL_TPV_ANNUEL.find((segment) =>
    segment.places.length === 1
      ? between(nombre_places, 0, segment?.places[0])
      : between(nombre_places, segment?.places[0], segment?.places[1])
  );

  if (line) {
    let ligne_puissance_fiscale = line.force_fiscale.find((ligne_puissance) =>
      ligne_puissance.values.length === 1
        ? between(puissance_fiscale, 0, ligne_puissance?.values[0])
        : between(
            puissance_fiscale,
            ligne_puissance?.values[0],
            ligne_puissance?.values[1]
          )
    );

    if (puissance_fiscale > FORCE_FISCALE_MAX) {
      ligne_puissance_fiscale =
        line.force_fiscale[line.force_fiscale.length - 1];
    }

    tarif = ligne_puissance_fiscale?.tarif || 0;
  }
  return tarif;
};

export const between = (value, first, last) => {
  let lower = Math.min(first, last),
    upper = Math.max(first, last);
  return value >= lower && value <= upper;
};

export function conversionCV(cv, type) {
  // Tableau des correspondances diesel/essence
  const table = [
    { diesel: 1, essence: 1 },
    { diesel: 2, essence: 2 },
    { diesel: 3, essence: 4 },
    { diesel: 4, essence: 6 },
    { diesel: 5, essence: 7 },
    { diesel: 6, essence: 9 },
    { diesel: 7, essence: 10 },
    { diesel: 8, essence: 11 },
    { diesel: 9, essence: 13 },
    { diesel: 10, essence: 14 },
    { diesel: 11, essence: 16 },
    { diesel: 12, essence: 17 },
    { diesel: 13, essence: 19 },
    { diesel: 14, essence: 20 },
    { diesel: 15, essence: 21 },
    { diesel: 16, essence: 23 },
    { diesel: 17, essence: 24 },
    { diesel: 18, essence: 26 },
    { diesel: 19, essence: 27 },
    { diesel: 20, essence: 29 },
    { diesel: 21, essence: 30 },
  ];

  // Recherche de la correspondance dans le tableau
  const correspondance = table.find((c) => c[type] === cv);

  // Renvoi de la correspondance pour l'autre type de moteur
  return correspondance
    ? correspondance[type === "diesel" ? "essence" : "diesel"]
    : 0;
}

export const generateAvenantserieNumber = (numero_avenant = "") => {
  let initialCode = "0000000";
  return initialCode
    .slice(-(initialCode.length - numero_avenant?.trim()?.length))
    .concat(numero_avenant);
};
