import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ authValue, children }) {
  if (!authValue) {
    return <Navigate to="/connexion" replace />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
