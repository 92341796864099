import axios from "axios";
import { USER_STORAGE } from "../constants";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  try {
    let userData = JSON.parse(sessionStorage.getItem(USER_STORAGE));
    return (config.headers["x-access-token"] = userData?.token);
  } catch (error) {
  } finally {
    return config;
  }
});
