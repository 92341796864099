import { BrowserRouter, Routes, Route } from "react-router-dom";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import CarBrandsPage from "../pages/dictionnaire/CarBrandsPage";
import CategoriesContrats from "../pages/dictionnaire/CategoriesContrats";
import CivilitiesPage from "../pages/dictionnaire/CivilitiesPage";
import CountriesPage from "../pages/dictionnaire/CountriesPage";
import JobsPage from "../pages/dictionnaire/JobsPage";
import PiecesOfficiellesPage from "../pages/dictionnaire/PiecesOfficiellesPage";
import SourcesEnergies from "../pages/dictionnaire/SourcesEnergies";
import ZonesCirculation from "../pages/dictionnaire/ZonesCirculation";
import IntermediaireDetails from "../pages/intermediaires/IntermediaireDetails";
import IntermediairesList from "../pages/intermediaires/IntermediairesList";
import SouscriptionList from "../pages/souscriptions/SouscriptionMonoList";
import UsersList from "../pages/users/UsersList";

function PoolRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/intermediaires/*">
          <Route index element={<IntermediairesList />} />
          <Route path="details/:uuid" element={<IntermediaireDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route index element={<SouscriptionList />} />
        </Route>
        <Route path="dictionnaire/*">
          <Route path="categories-contrats" element={<CategoriesContrats />} />
          <Route path="sources-energies" element={<SourcesEnergies />} />
          <Route path="zones-circulation" element={<ZonesCirculation />} />
          <Route path="pays" element={<CountriesPage />} />
          <Route path="civilites" element={<CivilitiesPage />} />
          <Route path="professions" element={<JobsPage />} />
          <Route path="car-brands" element={<CarBrandsPage />} />
          <Route path="pieces-officielles" element={<PiecesOfficiellesPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default PoolRoutes;
