export const USER_STORAGE = "jpBHjJNW1ONux3sTZeOdEkySJWUPOYfU";

export const POURCENTAGE_ASSURANCE_DUREE = {
  3: 26,
  6: 51,
};

export const TYPES_INTERMEDIAIRE = {
  AGENT_GENERAL: "AGENT_GENERAL",
  COURTIER: "COURTIER",
};

export const USERS_ROLE_ENUMS = {
  ROOT_ADMIN: "ROOT_ADMIN",
  POOL_USER: "POOL_USER",
  COMPANY_USER: "COMPANY_USER",
  COURTIER_USER: "COURTIER_USER",
  AGENT_GENERAL_USER: "AGENT_GENERAL_USER",
  COMPANY_AGENCY_USER: "COMPANY_AGENCY_USER",
  INTERMEDIAIRE_AGENCY_USER: "INTERMEDIAIRE_AGENCY_USER",
};

export const USERS_BASE_ROUTES = {
  ROOT_ADMIN: "pool",
  POOL_USER: "pool",
  COMPANY_USER: "company",
  COURTIER_USER: "intermediaire",
  AGENT_GENERAL_USER: "intermediaire",
  COMPANY_AGENCY_USER: "agency",
  INTERMEDIAIRE_AGENCY_USER: "agency",
};

export const USERS_ROLE_ENUMS_OPTIONS = [
  {
    key: "DEFAULT",
    text: "Choisir...",
    value: "",
  },
  {
    key: USERS_ROLE_ENUMS.ROOT_ADMIN,
    text: "Super Admnistrateur",
    value: USERS_ROLE_ENUMS.ROOT_ADMIN,
  },
  {
    key: USERS_ROLE_ENUMS.POOL_USER,
    text: "Utilisateur du pool",
    value: USERS_ROLE_ENUMS.POOL_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COMPANY_USER,
    text: "Utilisateur de compagnie",
    value: USERS_ROLE_ENUMS.COMPANY_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COURTIER_USER,
    text: "Utilisateur Courtier",
    value: USERS_ROLE_ENUMS.COURTIER_USER,
  },
  {
    key: USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
    text: "Utilisateur Agent Général",
    value: USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
  },
];
export const LIENS_SOUSCRIPEUR_ASSURE = [
  {
    key: "DEFAULT",
    text: "Choisir...",
    value: "",
  },
  {
    key: "LUI_MEME",
    text: "Lui Même",
    value: "LUI_MEME",
  },
  {
    key: "ASCENDANT",
    text: "Ascendant",
    value: "ASCENDANT",
  },
  {
    key: "BEAUX_PARENT",
    text: "Beaux-Parent",
    value: "BEAUX_PARENT",
  },
  {
    key: "DESCENDANT",
    text: "Descendant",
    value: "DESCENDANT",
  },
  {
    key: "EMPLOYE",
    text: "Employé",
    value: "EMPLOYE",
  },
  {
    key: "MEMBRE",
    text: "Membre",
    value: "MEMBRE",
  },
  {
    key: "PARENT",
    text: "Parent",
    value: "PARENT",
  },
];

export const PUISSANCE_FISCALE_DIESEL_CV = [
  {
    diesel: 1,
    essence: 1,
  },
  {
    diesel: 2,
    essence: 2,
  },
  {
    diesel: 3,
    essence: 4,
  },
  {
    diesel: 4,
    essence: 6,
  },
  {
    diesel: 5,
    essence: 7,
  },
  {
    diesel: 6,
    essence: 9,
  },
  {
    diesel: 7,
    essence: 10,
  },
  {
    diesel: 8,
    essence: 11,
  },
  {
    diesel: 9,
    essence: 13,
  },
  {
    diesel: 10,
    essence: 14,
  },
  {
    diesel: 11,
    essence: 16,
  },
  {
    diesel: 12,
    essence: 17,
  },
  {
    diesel: 13,
    essence: 19,
  },
  {
    diesel: 14,
    essence: 20,
  },
  {
    diesel: 15,
    essence: 21,
  },
  {
    diesel: 16,
    essence: 23,
  },
  {
    diesel: 17,
    essence: 24,
  },
  {
    diesel: 18,
  },
  {
    diesel: 19,
  },
  {
    diesel: 20,
  },
  {
    diesel: 21,
  },
];

export const TARIF_POOL_TPV_ANNUEL = [
  {
    places: [3],
    force_fiscale: [
      {
        values: [1, 4],
        tarif: 167407,
      },
      {
        values: [5, 8],
        tarif: 211727,
      },
      {
        values: [9, 13],
        tarif: 251113,
      },
      {
        values: [14, 20],
        tarif: 300342,
      },
      {
        values: [21],
        tarif: 349582,
      },
    ],
  },
  {
    places: [4],
    force_fiscale: [
      {
        values: [1, 4],
        tarif: 177261,
      },
      {
        values: [5, 8],
        tarif: 221582,
      },
      {
        values: [9, 13],
        tarif: 275720,
      },
      {
        values: [14, 20],
        tarif: 324970,
      },
      {
        values: [21],
        tarif: 369275,
      },
    ],
  },
  {
    places: [5, 6],
    force_fiscale: [
      {
        values: [1, 4],
        tarif: 206792,
      },
      {
        values: [5, 8],
        tarif: 241269,
      },
      {
        values: [9, 13],
        tarif: 300342,
      },
      {
        values: [14, 20],
        tarif: 349583,
      },
      {
        values: [21],
        tarif: 384337,
      },
    ],
  },
  {
    places: [7, 8],
    force_fiscale: [
      {
        values: [1, 4],
        tarif: 221566,
      },
      {
        values: [5, 8],
        tarif: 270806,
      },
      {
        values: [9, 13],
        tarif: 320040,
      },
      {
        values: [14, 20],
        tarif: 378940,
      },
      {
        values: [21],
        tarif: 412298,
      },
    ],
  },
  {
    places: [9, 10],
    force_fiscale: [
      {
        values: [1, 4],
        tarif: 251108,
      },
      {
        values: [5, 8],
        tarif: 295428,
      },
      {
        values: [9, 13],
        tarif: 339738,
      },
      {
        values: [14, 20],
        tarif: 403751,
      },
      {
        values: [21],
        tarif: 443137,
      },
    ],
  },
  {
    places: [11, 15],
    force_fiscale: [
      {
        values: [1, 4],
        tarif: 300353,
      },
      {
        values: [5, 8],
        tarif: 344668,
      },
      {
        values: [9, 13],
        tarif: 379124,
      },
      {
        values: [14, 20],
        tarif: 452996,
      },
      {
        values: [21],
        tarif: 492366,
      },
    ],
  },
  {
    places: [16, 20],
    force_fiscale: [
      {
        values: [5, 8],
        tarif: 403751,
      },
      {
        values: [9, 13],
        tarif: 428363,
      },
      {
        values: [14, 20],
        tarif: 507182,
      },
      {
        values: [21],
        tarif: 551460,
      },
    ],
  },
  {
    places: [21, 25],
    force_fiscale: [
      {
        values: [5, 8],
        tarif: 443137,
      },
      {
        values: [9, 13],
        tarif: 467759,
      },
      {
        values: [14, 20],
        tarif: 541606,
      },
      {
        values: [21],
        tarif: 590846,
      },
    ],
  },
  {
    places: [26, 30],
    force_fiscale: [
      {
        values: [5, 8],
        tarif: 472679,
      },
      {
        values: [9, 13],
        tarif: 516989,
      },
      {
        values: [14, 20],
        tarif: 576062,
      },
      {
        values: [21],
        tarif: 620387,
      },
    ],
  },
  {
    places: [31, 40],
    force_fiscale: [
      {
        values: [9, 13],
        tarif: 581669,
      },
      {
        values: [14, 20],
        tarif: 654843,
      },
      {
        values: [21],
        tarif: 699158,
      },
    ],
  },
  {
    places: [41, 50],
    force_fiscale: [
      {
        values: [9, 13],
        tarif: 659778,
      },
      {
        values: [14, 20],
        tarif: 723781,
      },
      {
        values: [21],
        tarif: 768086,
      },
    ],
  },
  {
    places: [51, 60],
    force_fiscale: [
      {
        values: [14, 20],
        tarif: 802562,
      },
      {
        values: [21],
        tarif: 846867,
      },
    ],
  },
  {
    places: [61, 70],
    force_fiscale: [
      {
        values: [14, 20],
        tarif: 861641,
      },
      {
        values: [21],
        tarif: 915805,
      },
    ],
  },
  {
    places: [71, 80],
    force_fiscale: [
      {
        values: [21],
        tarif: 1004430,
      },
    ],
  },
  {
    places: [80, 100],
    force_fiscale: [
      {
        values: [21],
        tarif: 1137224,
      },
    ],
  },
];
