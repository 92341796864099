import { BrowserRouter, Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import CompanyAgencyDetails from "../pages/compagnies_agencies/CompanyAgencyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import CompanyPartnersDetails from "../pages/partenaires/CompanyPartnersDetails";
import CompanyPartnersList from "../pages/partenaires/CompanyPartnersList";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import UsersList from "../pages/users/UsersList";
import SouscriptionMonoAdd from "../pages/souscriptions/SouscriptionMonoAdd";
import SouscriptionMonoList from "../pages/souscriptions/SouscriptionMonoList";
import PolicesList from "../pages/polices/PolicesList";
import PoliceDetails from "../pages/polices/PoliceDetails";

function CompanyRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="details" element={<CompanyDetails />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscripteurs/*">
          <Route index element={<SouscripteursList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route path="mono" element={<SouscriptionMonoList />} />
          <Route path="mono/add" element={<SouscriptionMonoAdd />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="agences/*">
          <Route index element={<SouscriptionMonoList />} />
          <Route path="details/:id" element={<CompanyAgencyDetails />} />
          <Route path="add" element={<SouscriptionMonoAdd />} />
        </Route>
        <Route path="partenaires/*">
          <Route index element={<CompanyPartnersList />} />
          <Route path="details/:uuid" element={<CompanyPartnersDetails />} />
        </Route>
        <Route path="polices/*">
          <Route index element={<PolicesList />} />
          <Route path="details/:id" element={<PoliceDetails />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default CompanyRoutes;
