import { Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import SouscripteurDetails from "../pages/souscripteurs/SouscripteurDetails";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import UsersList from "../pages/users/UsersList";
import SouscriptionMonoAdd from "../pages/souscriptions/SouscriptionMonoAdd";
import SouscriptionMonoList from "../pages/souscriptions/SouscriptionMonoList";

function AgencyRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="details" element={<CompanyDetails />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscripteurs/*">
          <Route index element={<SouscripteursList />} />
          <Route path="details/:uuid" element={<SouscripteurDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route path="mono" element={<SouscriptionMonoList />} />
          <Route path="mono/create" element={<SouscriptionMonoAdd />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="agences/*">
          <Route path="mono" element={<SouscriptionMonoList />} />
          <Route path="mono/create" element={<SouscriptionMonoAdd />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AgencyRoutes;
