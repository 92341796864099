import React from "react";
import {
  Form,
  Input,
} from "semantic-ui-react";

import dayjs from "dayjs";

function ReferencesConditions({ formik }) {
  const { values, errors, handleBlur, handleChange, setFieldValue } = formik;
  return (
    <React.Fragment>
      <Form.Group widths={"equal"}>
        <Form.Field error={"date_proposition" in errors} required>
          <label>Date de proposition</label>
          <Input
            type="datetime-local"
            value={values.date_proposition}
            onChange={handleChange("date_proposition")}
            onBlur={handleBlur("date_proposition")}
          />
          <small className="field-error">{errors.date_proposition}</small>
        </Form.Field>
        <Form.Field error={"date_effet" in errors} required>
          <label>Date d'éffet</label>
          <Input
            type="datetime-local"
            value={values.date_effet}
            onChange={(e, data) => {
              setFieldValue(
                "date_effet",
                dayjs(data.value).format("YYYY-MM-DD HH:mm"),
                true
              );
              const month = values?.type_renouvellement?.split("_")[0];
              setFieldValue(
                "date_echeance",
                dayjs(dayjs(data.value).format("YYYY-MM-DD HH:mm"))
                  .add(Number(month), "months").add(-1,"day")
                  .format("YYYY-MM-DD HH:mm"),
                true
              );
            }}
            onBlur={handleBlur("date_effet")}
          />
          <small className="field-error">{errors.date_effet}</small>
        </Form.Field>
        <Form.Field error={"type_renouvellement" in errors} required>
          <label>Renouvellement</label>
          <Form.Select
            options={[
              {
                key: "12_MOIS",
                text: "12 mois",
                value: "12_MOIS",
              },
              {
                key: "6_MOIS",
                text: "06 mois",
                value: "6_MOIS",
              },
              {
                key: "3_MOIS",
                text: "03 mois",
                value: "3_MOIS",
              },
            ]}
            value={values.type_renouvellement}
            onChange={(e, data) => {
              setFieldValue("type_renouvellement", data.value, true);
              const month = data.value.split("_")[0];
              setFieldValue(
                "date_echeance",
                dayjs(values.date_effet)
                  .add(Number(month), "months").add(-1,"day")
                  .format("YYYY-MM-DD HH:mm"),
                true
              );
            }}
          />
          <small className="field-error">{errors.type_renouvellement}</small>
        </Form.Field>
        <Form.Field required error={"date_echeance" in errors}>
          <label>Date d'échéance</label>
          <Input
            type="datetime-local"
            readOnly
            value={values.date_echeance}
            onChange={handleChange("date_echeance")}
            onBlur={handleBlur("date_echeance")}
          />
          <small className="field-error">{errors.date_echeance}</small>
        </Form.Field>
      </Form.Group>
      <Form.Field required error={"type_avenant" in errors}>
        <label>Type d'avenant</label>
        <Form.Select
          readOnly
          disabled
          options={[
            {
              key: "AFFAIRE_NOUVELLE",
              text: "AFFAIRE NOUVELLE",
              value: "AFFAIRE_NOUVELLE",
            },
            {
              key: "RENOUVELLEMEMNT",
              text: "RENOUVELLEMENT",
              value: "RENOUVELLEMEMNT",
            },
            { key: "RESILIATION", text: "RESILIATION", value: "RESILIATION" },
            { key: "SUSPENSION", text: "SUSPENSION", value: "SUSPENSION" },
            {
              key: "MODIFICATION_PRISE_EFFET",
              text: "MODIFICATION DE PRISE D'EFFET",
              value: "MODIFICATION_PRISE_EFFET",
            },
            {
              key: "CHANGEMENT_IMMATRICULATION",
              text: "CHANGEMENT D'IMMATRICULATION",
              value: "CHANGEMENT_IMMATRICULATION",
            },
            {
              key: "REMISE_VIGUEUR",
              text: "REMISE EN VIGUEUR",
              value: "REMISE_VIGUEUR",
            },
            {
              key: "INCORPORATION",
              text: "INCORPORATION",
              value: "INCORPORATION",
            },
            { key: "RETRAIT", text: "RETRAIT", value: "RETRAIT" },
            {
              key: "EMISSION_ATTESTATION",
              text: "EXTENSION DE GARANTIES",
              value: "EXTENSION_ATTESTATION",
            },
            {
              key: "EXTENSION_GARANTIES",
              text: "EXTENSION DE GARANTIES",
              value: "EXTENSION_GARANTIES",
            },
            {
              key: "RETRAIT_GARANTIES",
              text: "RETRAIT DE GARANTIES",
              value: "RETRAIT_GARANTIES",
            },
            {
              key: "AVENANT_CHANGEMENT_NOM",
              text: "AVENANT DE CHANGEMENT DE NOM",
              value: "AVENANT_CHANGEMENT_NOM",
            },
          ]}
          value={values.type_avenant}
          onChange={(e, data) => {
            setFieldValue("type_avenant", data.value);
          }}
        />
        <small className="field-error">{errors.type_avenant}</small>
      </Form.Field>
      <Form.Group widths={"equal"}></Form.Group>
    </React.Fragment>
  );
}

export default ReferencesConditions;
