import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  List,
  Segment,
  Tab,
} from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import * as policesService from "../../services/policesService";
import dayjs from "dayjs";
import ActesDeGestionPane from "../../components/detailsPolice/ActesDeGestionPane";
import SinistresPane from "../../components/detailsPolice/SinistresPane";
import ConditionsParticulieresPreviewModal from "../../components/detailsPolice/ConditionsParticulieresPreviewModal";

function PoliceDetails() {
  const [police, setPolice] = useState();
  const [
    openConditionsParticulieresPreview,
    setOpenConditionsParticulieresPreview,
  ] = useState(false);

  const params = useParams();

  const fetchPolice = async () => {
    try {
      const response = await policesService.findOne(params?.id);

      if (response?.status === 200) {
        setPolice(response?.data);
        console.log(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchPolice();
    return () => {};
  }, [params?.id]);

  return (
    <Page title="Details de la police">
      <GridRow className="CompagnyDetails">
        <Grid>
          <Grid.Column width={8} style={{ display: "flex" }}>
            <Header style={{ alignSelf: "center" }}>Détails de la police</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Dropdown
              pointing="right"
              style={{ zIndex: 999 }}
              icon={null}
              trigger={
                <Button
                  style={{ marginLeft: "10px" }}
                  circular
                  icon={<Icon name="ellipsis vertical" />}
                />
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setOpenConditionsParticulieresPreview(true)}
                  content="Conditions particulières"
                />
                <Dropdown.Item content="Carte Rose" />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid>
        <Divider />
        <GridRow as={Segment}>
          <Label ribbon>{police?.num_police || "N/A"}</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Code client</List.Header>
                    <List.Description>
                      {
                        police?.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.num
                      }
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Immatriculation</List.Header>
                    <List.Description>
                      {police?.avenants[0]?.vehicules?.immatriculation}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Statut</List.Header>
                    <List.Description>
                      {police?.avenants[0]?.types_avenants?.code}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Noms/Dénomination</List.Header>
                    <List.Description>
                      {police?.souscriptions
                        ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                        ?.groupe_souscripteur === "PARTICULIER"
                        ? `${police?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name} ${police?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}`
                        : police?.souscriptions
                            ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                            ?.denomination}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Date d'éffet</List.Header>
                    <List.Description>
                      {dayjs(police?.avenants.date_effet).format("DD-MM-YYYY")}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Type de client/</List.Header>
                    <List.Description>
                      {
                        police?.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.groupe_souscripteur
                      }
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Echéance</List.Header>
                    <List.Description>
                      {dayjs(police?.avenants[0]?.date_effet)
                        .add(
                          Number(police?.avenants[0]?.renouvellement.slice(-2)),
                          "months"
                        )
                        .add(-1, "day")
                        .format("DD-MM-YYYY")}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment} style={{ marginBottom: "100px" }}>
          <Tab
            panes={[
              {
                menuItem: "Actes de gestion",
                render: () => (
                  <Tab.Pane>
                    <ActesDeGestionPane
                      avenants={police?.avenants}
                      onSuccessPaymentCallBack={fetchPolice}
                      onSuccessCallBack={fetchPolice}
                      idpolices={police?.idpolices}
                    />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: "Sinistres",
                render: () => (
                  <Tab.Pane>
                    <SinistresPane />
                  </Tab.Pane>
                ),
              },
            ]}
          />
        </GridRow>
      </GridRow>
      <ConditionsParticulieresPreviewModal
        open={openConditionsParticulieresPreview}
        setOpen={setOpenConditionsParticulieresPreview}
        idpolices={police?.idpolices}
      />
    </Page>
  );
}

export default PoliceDetails;
