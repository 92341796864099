import React from "react";
import {
  Button,
  Divider,
  Form,
  Input,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as souscripteursService from "../../services/souscripteursService";

const validationSchema = yup.object().shape({
  denomination: yup.string().max(255).required(),
  carte_contribuable: yup.string().max(50),
  registre_commerce: yup.string().max(50),
  nom_dirigeant: yup.string(),
  address: yup.string().max(255).required(),
  phone: yup.string().min(8).max(16).required(),
  email: yup.string().email(),
  groupe_souscripteur: yup.string().oneOf(["ENTREPRISE"]).required(),
});
function EntrepriseForm({ callback = () => {}, open = false, setOpen }) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      carte_contribuable: "",
      registre_commerce: "",
      nom_dirigeant: "",
      email: "",
      address: "",
      phone: "",
      groupe_souscripteur: "ENTREPRISE",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      souscripteursService
        .create(values)
        .then(
          (response) => {
            toast(`Souscripteur ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;
  return (
    <Form size="tiny">
      <Form.Field required error={"denomination" in errors}>
        <label>Dénomination</label>
        <Input
          value={values.denomination}
          onChange={handleChange("denomination")}
          onBlur={handleBlur("denomination")}
        />
        <small className="field-error">{errors.denomination}</small>
      </Form.Field>
      <Form.Group widths={"equal"}>
        <Form.Field error={"carte_contribuable" in errors}>
          <label>Carte de contribuable</label>
          <Input
            value={values.carte_contribuable}
            onChange={handleChange("carte_contribuable")}
            onBlur={handleBlur("carte_contribuable")}
          />
          <small className="field-error">{errors.carte_contribuable}</small>
        </Form.Field>
        <Form.Field error={"registre_commerce" in errors}>
          <label>Registre de commerce</label>
          <Input
            value={values.registre_commerce}
            onChange={handleChange("registre_commerce")}
            onBlur={handleBlur("registre_commerce")}
          />
          <small className="field-error">{errors.registre_commerce}</small>
        </Form.Field>
        <Form.Field error={"nom_dirigeant" in errors}>
          <label>Nom du dirigeant</label>
          <Input
            value={values.nom_dirigeant}
            onChange={handleChange("nom_dirigeant")}
            onBlur={handleBlur("nom_dirigeant")}
          />
          <small className="field-error">{errors.nom_dirigeant}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field error={"idcountries" in errors} width={6}>
          <label>Pays</label>
          <Form.Select
            search
            fluid
            onChange={(e, data) => setFieldValue("idcountries", data.value)}
            placeholder="Choisr..."
          />
          <small className="field-error">{errors.idcountries}</small>
        </Form.Field>
        <Form.Field error={"phone" in errors} required width={6}>
          <label>Téléphone</label>
          <Input
            value={values.phone}
            onChange={handleChange("phone")}
            onBlur={handleBlur("phone")}
          />
          <small className="field-error">{errors.phone}</small>
        </Form.Field>
        <Form.Field error={"email" in errors} width={6}>
          <label>Email</label>
          <Input
            value={values.email}
            onChange={handleChange("email")}
            onBlur={handleBlur("email")}
          />
          <small className="field-error">{errors.email}</small>
        </Form.Field>
      </Form.Group>
      <Form.Field error={"address" in errors}>
        <label>Adresse</label>
        <Form.Input
          value={values.address}
          onChange={handleChange("address")}
          onBlur={handleBlur("address")}
        />
        <small className="field-error">{errors.address}</small>
      </Form.Field>
      <Divider />
      <Form.Field style={{ textAlign: "right" }}>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Form.Field>
    </Form>
  );
}

export default EntrepriseForm;
