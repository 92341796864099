import React, { Fragment, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import dayjs from "dayjs";
import { Header } from "semantic-ui-react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);
function ValidatedSouscriptionsChart({ height = "100%" }) {
  const data = {
    labels: ["Non Validé", "Validés"],
    datasets: [
      {
        label: "Souscriptions",
        data: [12, 19],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Fragment>
      <Doughnut data={data}  style={{ height: "300px" }} />
    </Fragment>
  );
}

export default ValidatedSouscriptionsChart;
