import React from "react";
import {
  Button,
  Form,
  Input,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/policesService";

const validationSchema = yup.object().shape({
  immatriculation_vehicule: yup.string().required(),
});
function ChangementImmatriculationModal({
  callback = () => {},
  open = false,
  setOpen,
  avenants,
  idpolices,
  onSuccessPaymentCallBack = () => {},
  onSuccessCallBack = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      immatriculation_vehicule: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        immatriculation_vehicule: values.immatriculation_vehicule,
        idpolices: idpolices,
      };
      policesService
        .changementImmaticulation(data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Immatriculation modifiée avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.message || "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      onUnmount={() => {
        handleReset();
      }}
      size="tiny"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Changement d'immatriculation</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui form">
            <Form.Field>
              <label>Immatriculation vehicule</label>
              <Input
                value={values.immatriculation_vehicule}
                onChange={handleChange("immatriculation_vehicule")}
                onBlur={handleBlur("immatriculation_vehicule")}
              />
              <small className="field-error">
                {errors.immatriculation_vehicule}
              </small>
            </Form.Field>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default ChangementImmatriculationModal;
