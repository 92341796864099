import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import CivilityFormModal from "../../components/modalForms/CivilityFormModal";
import Page from "../../components/Page";

import * as civilitiesService from "../../services/civilitiesService";
function CivilitiesPage() {
  const [civilities, setCivilities] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const fetchCivilities = async () => {
    try {
      const response = await civilitiesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setCivilities(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchCivilities();
    return () => {};
  }, []);

  return (
    <Page title="Pays">
      <GridRow className="CivilitiesPage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des civilités</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Libelle</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {civilities.map((civility, index) => {
                return (
                  <Table.Row key={`${civility.idcivilities}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{civility.libelle}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      </GridRow>
      <CivilityFormModal
        open={open}
        setOpen={setOpen}
        callback={fetchCivilities}
      />
    </Page>
  );
}

export default CivilitiesPage;
