import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import CompanyFormModal from "../../components/modalForms/CompanyFormModal";
import Page from "../../components/Page";

import * as compagniesService from "../../services/comagniesService";
function CompagniesList() {
  const [compagnies, setCompagnies] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const fetchCompagnies = async () => {
    try {
      const response = await compagniesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setCompagnies(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchCompagnies();
    return () => {};
  }, []);

  return (
    <Page title="Compagnies">
      <GridRow className="Compagnies">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Compagnies</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dénomination</Table.HeaderCell>
                <Table.HeaderCell>Siège Social</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {compagnies.map((company, index) => {
                return (
                  <Table.Row key={`${company.idcompagnies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{company.denomination}</Table.Cell>
                    <Table.Cell>{company.siege_social}</Table.Cell>
                    <Table.Cell>{company.email}</Table.Cell>
                    <Table.Cell>
                      {company.phone_1}/{company.phone_2}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() => navigate(`details/${company.uuid}`)}
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      </GridRow>
      <CompanyFormModal
        open={open}
        setOpen={setOpen}
        callback={fetchCompagnies}
      />
    </Page>
  );
}

export default CompagniesList;
